import request from '@/auth/jwt/useJwt'

// 授权接口-登录

/*  查询设备列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  let requestUrl = `logger/access/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=requestTime&sorts[0].order=desc`
  if (params) {
    let index = 0
    if (params.ip) {
      requestUrl += `&terms[${index}].column=ip&terms[${index}].value=${params.ip}`
      index++
    }
    if (params.requestTime) {
      requestUrl += `&terms[${index}].column=requestTime$btw&terms[${index}].value=${params.requestTime[0]},${params.requestTime[1]}`
      index++
    }
    if (params.action) {
      requestUrl += `&terms[${index}].column=action$LIKE&terms[${index}].value=%${params.action}%`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

export function query2(pageIndex, pageSize, params) {
  console.log('1111', params)
  return request.axiosIns({
    url: `logger/access/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=requestTime&sorts%5B0%5D.order=desc`,
    method: 'get',
  })
}
