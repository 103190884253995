<template>
  <div>
    <b-card class="" body-class="">
      <!--个人信息-->
      <b-row class="user-info">
        <b-col md="8">
          <div class="user-info d-flex align-items-center h-100">
            <b-img
              v-viewer
              :src="userInfo.avatar ? userInfo.avatar : require('@/assets/images/avatars/default_avatar.svg')  "
              blank-color="#ccc"
              fluid
              class="u-avatar bg-light-primary"
              alt="头像 "
            />
            <div class="d-flex flex-column ml-2 justify-content-between h-100">
              <div class="d-flex flex-column">
                <h2 class="mb-1 text-body">{{ $x.utils.Date.timeState() + '，'}} <span class="x-text-bold">{{userInfo.name}}</span></h2>
                <span class="x-line-2 text-secondary">{{ userInfo.description ? userInfo.description : '暂无描述' }}</span>
              </div>
              <div class="d-flex">
                <div class="bg-light-primary btn-sm rounded-pill mt-1 mr-1" v-for="(tenant, index) in userInfo.tenants" :key="index">{{ tenant.tenantName }}</div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="h-100 d-flex flex-column align-items-end justify-content-between pt-50 pb-50">
            <div class="d-flex align-items-center">
              <div v-ripple.400="$x.ripple.primary"
                   @click="$router.push({ name: 'device-center-device-manager-products' })"
                   class="bg-light-primary rounded-circle btn btn-icon  mr-50">
                <feather-icon icon="BoxIcon" size="18" class="x-link"></feather-icon>
              </div>
              <div v-ripple.400="$x.ripple.primary"
                   @click="$router.push({ name: 'device-center-device-manager-devices' })"
                   class="bg-light-primary rounded-circle btn btn-icon  mr-50">
                <feather-icon icon="InboxIcon" size="18" class="x-link"></feather-icon>
              </div>
              <div v-ripple.400="$x.ripple.primary"
                   @click="$router.push({ name: 'system-usercenter-basesettings' })"
                   class="bg-light-primary rounded-circle btn btn-icon  mr-50">
                <feather-icon icon="UserIcon" size="18" class="x-link"></feather-icon>
              </div>
            </div>
            <div class="d-flex align-items-center text-secondary">
              <feather-icon icon="ClockIcon" size="18"></feather-icon>
              <span class="ml-50">{{ '最近登录时间：' + (lastLoginTime ? lastLoginTime : '暂无') }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!--内容区域-->
    <b-row>
      <b-col md="7">
        <!--我的产品-->
        <b-card class="">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-img
                :src="require('@/assets/images/cx/product.svg') "
                fluid class="x-section-icon"
              />
              <div class="font-medium-2 x-text-bold ml-50">我的产品 (<span class="text-primary x-link" @click="$router.push({ name: 'device-center-device-manager-products' })">{{ '  ' + productCount + '  ' }}</span>)</div>
            </div>
            <span class="font-medium-1 text-primary x-link"
                  @click="$router.push({ name: 'device-center-device-manager-products' })">更多></span>
          </div>
          <b-row class="mt-1">
            <b-col v-if="productCount === 0" md="12">
              <b-img center class="mb-3 mt-2" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
            </b-col>
            <b-col md="6" lg="6" xl="4"  v-for="(pd, index) in products" :key="index">
              <b-card class="x-dashed-border x-w-100 pd-card x-anim-card">
                <b-img center class="position-absolute  pd-state-bg" :src="pd.state === 1 ? require('@/assets/images/cx/deployed.svg') : require('@/assets/images/cx/undeployed.svg')"/>
                <div class="d-flex align-items-center">
                  <b-avatar v-viewer :src="pd.photoUrl"
                            text="CX" variant="light-primary" class="pd-image">
                  </b-avatar>
                  <span class="x-text-bold ml-1 x-link x-text-cut" @click="toProduct(pd)">{{ pd.name }}</span>
                </div>
                <div class="mt-1 text-secondary d-flex align-items-center"> 设备数量<span class="ml-50 x-link text-primary" @click.stop="toDeviceList(pd)">{{ pd.count }}</span></div>
                <div class="mt-1 text-secondary d-flex align-items-center x-text-cut" v-tooltip.hover="pd.createTime.cxFormat()"> 创建时间<span class="ml-50 ">{{ pd.createTime.cxFormat() }}</span></div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
        <!--我的设备-->
        <b-card class="">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-img
                :src="require('@/assets/images/cx/device2.svg') "
                fluid class="x-section-icon"
              />
              <div class="font-medium-2 x-text-bold ml-50">我的设备 (<span class="text-primary x-link" @click="$router.push({ name: 'device-center-device-manager-devices' })">{{ '  ' + deviceCount + '  ' }}</span>)</div>
            </div>
            <span class="font-medium-1 text-primary x-link"
                  @click="$router.push({ name: 'device-center-device-manager-devices' })">更多></span>
          </div>
          <x-table
            :options="deviceOptions"
            :show-actions="false"
            no-page
            :card="false"
            :fixed="false"
            :page-sizes="[{index: 0, label: '6 条/页', value: 6}]"
            @search="searchHandle">
          </x-table>
          <b-row></b-row>
        </b-card>
        <!--动态-->
        <b-card class="">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-img
                :src="require('@/assets/images/cx/log.svg') "
                fluid class="x-section-icon"
              />
              <div class="font-medium-2 x-text-bold ml-50">动态 (<span class="text-primary x-link" @click="$router.push({ name: 'system-log-access' })">{{ '  ' + logCount + '条  ' }}</span>)</div>
            </div>
            <span class="font-medium-1 text-primary x-link"
                  @click="$router.push({ name: 'system-log-access' })">更多></span>
          </div>
          <b-col v-if="logCount === 0" md="12">
            <b-img center class="mb-3 mt-2" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
          </b-col>
          <app-timeline v-else class="mt-1">
            <app-timeline-item v-for="(log, index) in logs" :key="index"
                               :variant="getLogVariant(log)">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
                <b-badge variant="light-primary" class="mb-50">{{ log.context.username }}</b-badge>
                <small class="text-muted mt-50" v-tooltip.hover="log.requestTime.cxFormat()">{{ log.requestTime | timeAgo }}</small>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span>{{ log.action }}</span>
                <span class="text-muted">{{ '登录IP：' + log.ip }}</span>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-card>
      </b-col>
      <b-col md="5">
        <!--快捷导航-->
        <b-card class="">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-img
                :src="require('@/assets/images/cx/more.svg') "
                fluid class="x-section-icon"
              />
              <span class="font-medium-2 x-text-bold ml-50">快捷导航</span>
            </div>
          </div>
          <b-row class="mt-50 pl-2 pr-2">
            <b-col md="6" xl="4" v-for="(link, index) in authzQuickLinks(quickLinks)" :key="index">
              <div class="d-flex x-w-100 align-items-center justify-content-center quick-to pb-50 pt-50 m-50" @click="linkTo(link)">
                <b-img
                  :src="link.icon"
                  fluid class="x-section-icon"
                />
                <div class="font-medium-2 x-text-bold ml-50">{{ link.name }}</div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <!--设备告警-->
        <b-card class="">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-img
                :src="require('@/assets/images/cx/alarm.svg') "
                fluid class="x-section-icon"
              />
              <div class="font-medium-2 x-text-bold ml-50">设备告警 (<span class="text-primary x-link" @click="$router.push({ name: 'ops-alarm' })">{{ '  ' + alarmCount + '条  ' }}</span>)</div>
            </div>
            <div class="d-flex align-items-center">
              <b-button-group class="mr-1" size="sm">
                <div
                  v-ripple.400="$x.ripple.primary"
                  :class="alarmSearchState ? '' : 'active'"
                  class="btn btn-light-primary" style="text-align: center !important;"
                  @click="alarmSearchState = undefined">
                  <input type="radio" class="btn-check" name="account_team_size" value="1-1">
                  全部
                </div>
                <div
                  v-ripple.400="$x.ripple.primary"
                  :class="alarmSearchState === 'solve' ? 'active' : ''"
                  class="btn btn-light-primary"
                  @click="alarmSearchState = 'solve'">
                  <input type="radio" class="btn-check" name="account_team_size" value="1-1">
                  已处理
                </div>
                <div
                  v-ripple.400="$x.ripple.primary"
                  :class="alarmSearchState === 'newer' ? 'active' : ''"
                  class="btn btn-light-primary"
                  @click="alarmSearchState = 'newer'">
                  <input type="radio" class="btn-check" name="account_team_size" value="1-1">
                  未处理
                </div>
              </b-button-group>
              <span class="font-medium-1 text-primary x-link" @click="$router.push({ name: 'ops-alarm' })">更多></span>
            </div>
          </div>
          <x-table
            ref="alarmTable"
            :options="alarmOptions"
            :fixed="false"
            no-page
            :card="false"
            @rowDetail="rowDetail"
            @rowSolve="rowSolve"
            @search="alarmSearchHandle">
            <template slot="alarmName" slot-scope="{ row }">
              <div class="badge badge-light-danger">
               {{ row.alarmName }}
              </div>
            </template>
          </x-table>
          <b-modal
            id="modal-view"
            ref="myModal"
            size="lg"
            scrollable
            title-class="x-text-bold"
            body-class="pl-4 pr-4"
            cancel-variant="flat-secondary"
            ok-title="确定"
            cancel-title="取消"
            centered
          >
            <alarm-detail v-model="alarmDetail" style="height: 60vh"></alarm-detail>
          </b-modal>
          <b-modal
            id="modal-solve"
            ref="solveModal"
            title-class="x-text-bold"
            body-class="pl-4 pr-4"
            cancel-variant="flat-secondary"
            ok-title="处理"
            cancel-title="取消"
            centered
            title="处理警告"
            @ok="solve"
          >
            <x-form-validator ref="refFormObserver">
              <x-form-feild label="处理结果" require>
                <b-form-textarea
                  v-model="solveText"
                  placeholder="请输入处理结果"
                />
              </x-form-feild>
            </x-form-validator>
          </b-modal>
        </b-card>
        <!--产品占比分析-->
        <b-card class="">
          <div class="d-flex align-items-center">
            <b-img
              :src="require('@/assets/images/cx/sort.svg') "
              fluid class="x-section-icon"
            />
            <span class="font-medium-2 x-text-bold ml-50 text-center">产品占比排行</span>
          </div>
          <b-row>
            <b-col md="12" v-for="(item, index) in productsClassfy" :key="index" class="mt-1 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-avatar
                  :text="(index + 1) + ''"
                  :variant="getVariant(index)"
                />
                <span class="font-medium-2 x-text-bold ml-1 text-center x-text-cut">{{ item.name }}</span>
              </div>
              <span class="x-link text-primary" @click="toProduct(item)">{{ item.count }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import { BCard, BImg, BRow, BCol, BAvatar, BBadge, BFormTextarea, BModal, BButtonGroup } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AlarmDetail from '@/views/ops/alarm/AlarmDetail.vue'
import { detail } from '@/api/system/usercenter/basesettings'
import { query as queryLog } from '@/api/system/log/access'
import { queryAllProducts, count as deviceCount } from '@/api/device-manage/product-list'
import { query as queryDevice } from '@/api/device-manage/device-list'
import { alarmLogSolve, getDeviceAlarmRecord } from '@/api/ops/alarm-record'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    XTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormTextarea,
    BModal,
    XFormValidator,
    XFormFeild,
    AlarmDetail,
    BButtonGroup,
  },
  data() {
    return {
      userInfo: {},
      lastLoginTime: undefined,
      products: [],
      productsClassfy: [],
      // 排名值
      productSort: 6,
      productCount: 0,
      deviceCount: 0,
      logs: [],
      logCount: 0,
      alarmCount: 0,
      alarmDetail: undefined,
      solveText: undefined,
      quickLinks: [
        { icon: require('@/assets/images/cx/product.svg'), name: '我的产品', route: 'device-center-device-manager-products', action: 'query', resource: 'device-product' },
        { icon: require('@/assets/images/cx/device2.svg'), name: '我的设备', route: 'device-center-device-manager-devices', action: 'query', resource: 'device-api' },
        { icon: require('@/assets/images/cx/device-group.svg'), name: '设备分组', route: 'device-center-device-manager-device-group', action: 'query', resource: 'device-group' },
        { icon: require('@/assets/images/cx/big-screen.svg'), name: '数据大屏', route: 'data-center-big-screen', action: 'query', resource: 'big-screen-web' },
        { icon: require('@/assets/images/cx/data-forward.svg'), name: '数据转发', route: 'data-center-rule-engine-forward', action: 'query', resource: 'rule-instance' },
        { icon: require('@/assets/images/cx/linkage.svg'), name: '场景联动', route: 'data-center-rule-engine-scene-linkage', action: 'query', resource: 'rule-instance' },
        { icon: require('@/assets/images/cx/distribution.svg'), name: '设备分布', route: 'ops-location', action: 'find-geo', resource: 'geo-manager' },
        { icon: require('@/assets/images/cx/upgrade.svg'), name: '固件升级', route: 'ops-firmware', action: 'query', resource: 'firmware-manager' },
        { icon: require('@/assets/images/cx/alarm.svg'), name: '设备告警', route: 'ops-alarm', action: 'query', resource: 'device-alarm' },
        // { icon: require('@/assets/images/cx/notice.svg'), name: '通知模版', route: 'system-notify-template' },
        // { icon: require('@/assets/images/cx/security.svg'), name: '安全中心', route: 'system-usercenter-securitysettings' },
        // { icon: require('@/assets/images/cx/user.svg'), name: '用户管理', route: 'system-setup-user' },
        // { icon: require('@/assets/images/cx/role.svg'), name: '角色管理', route: 'system-setup-role' },
        // { icon: require('@/assets/images/cx/org.svg'), name: '机构管理', route: 'system-setup-org' },
        // { icon: require('@/assets/images/cx/log.svg'), name: '访问日志', route: 'system-log-access' },
      ],
      deviceOptions: {
        formType: 'sidebar',
        formWidth: '60%',
        hideStyleSwitch: true,
        actions: [],
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          editDisable: false,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入标识',
          },
        }, {
          label: '设备名称',
          labelShow: true,
          prop: 'name',
          linkUrl: '/device-center/device/view/{id}',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入设备名称',
          },
        }, {
          label: '产品',
          labelShow: true,
          prop: 'productId',
          linkUrl: '/device-center/products/view/{productId}',
          type: 'select',
          dictUrl: 'device-product/_query/no-paging?paging=false',
          props: { label: 'name', value: 'id' },
          getDictLabel: option => option.label + `（${option.value})`,
          onDictSelected: (option, column, formData) => {
            column[2].dictData.forEach(product => {
              if (option === product.value) {
                formData.productName = product.label
              }
            })
          },
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请选择产品',
          },
        }, {
          label: '产品名称',
          labelShow: true,
          prop: 'productName',
          editShow: false,
          addShow: false,
          viewShow: false,
          rowShow: false,
          searchShow: false,
          rules: {
            rule: 'required',
            message: '请输入产品名称',
          },
        }, {
          label: '所属机构',
          prop: 'orgId',
          type: 'select',
          dictUrl: '/organization/_all',
          props: { label: 'name', value: 'id' },
          rowShow: false,
          sortable: true,
          searchShow: false,
        }, {
          label: '注册时间',
          labelShow: true,
          prop: 'registryTime',
          type: 'datetime',
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'notActive', text: '未启用' }, { value: 'online', text: '在线' }, { value: 'offline', text: '离线' }],
          selectVariant: prop => {
            if (prop.value === 'notActive') {
              return 'text-warning'
            }
            if (prop.value === 'online') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
        }, {
          label: '描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rows: 5,
          searchShow: false,
          rowShow: false,
        },
        ],
      },
      alarmSearchState: undefined,
      alarmOptions: {
        hideStyleSwitch: true,
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        resetBtn: false,
        actions: [
          { action: 'detail', name: '详情', icon: 'EyeIcon', tip: '详情' },
          { action: 'solve', name: '处理', icon: 'FeatherIcon', tip: '处理' }],
        actionFilter: (action, row) => {
          if (row.state === 'solve') {
            return action === 'detail'
          }
          return action === 'detail' || action === 'solve'
        },
        columns: [{
          label: '设备',
          labelShow: true,
          prop: 'deviceName',
          linkUrl: '/device-center/device/view/{deviceId}',
          searchShow: false,
        }, {
          label: '所属产品',
          labelShow: true,
          prop: 'productName',
          linkUrl: '/device-center/products/view/{productId}',
          rowShow: false,
          addShow: false,
          editShow: false,
          searchShow: false,
        }, {
          label: '告警名称',
          labelShow: true,
          prop: 'alarmName',
          rowShow: true,
          searchShow: false,
        }, {
          label: '处理状态',
          labelShow: true,
          type: 'select',
          prop: 'state',
          rowSelect: true,
          dictData: [{ state: '全部', id: undefined }, { state: '未处理', id: 'newer' }, { state: '已处理', id: 'solve' }],
          props: { label: 'state', value: 'id' },
          selectVariant: prop => {
            if (prop === 'solve') {
              return 'success'
            }
            return 'danger'
          },
          searchShow: false,
        }, {
          label: '告警时间',
          labelShow: true,
          sortable: true,
          prop: 'alarmTime',
          type: 'datetime',
          addShow: false,
          editShow: false,
          searchShow: false,
        },
        ],
      },
    }
  },
  watch: {
    alarmSearchState() {
      this.$refs.alarmTable.__searchHandle()
    },
  },
  created() {
    detail().then(resp => {
      this.userInfo = resp.data.result
    })
    queryLog(0, 1, { action: '授权接口-登录' }).then(resp => {
      let lastLog = resp.data.result.data
      this.lastLoginTime = (lastLog && lastLog.length === 1) ? lastLog[0].requestTime.cxFormat() : '暂无'
    })
    queryLog(0, 6).then(resp => {
      this.logCount = resp.data.result.total
      this.logs = resp.data.result.data
    })
    queryAllProducts().then(resp => {
      let pds = resp.data.result
      this.productCount = pds.length
      if (pds.length > 6) {
        this.products = pds.slice(0, 6)
      } else {
        this.products = pds
      }
      let index = 0
      pds.forEach(item => {
        deviceCount(item.id).then(resp2 => {
          index++
          item.count = resp2.data.result
          if (index === pds.length) {
            pds = pds.sort((a, b) => b.count - a.count)
            if (pds.length > this.productSort) {
              let other = {
                name: '其他',
                photoUrl: '',
                count: 0,
              }
              let i = 0
              pds.forEach(pd => {
                i++
                if (i <= this.productSort) {
                  this.productsClassfy.push(pd)
                } else {
                  other.count += pd.count
                }
              })
              this.productsClassfy.push(other)
            } else {
              this.productsClassfy = pds
            }
          }
        })
      })
    })
  },
  methods: {
    searchHandle(page, params, done) {
      queryDevice(page.pageIndex - 1, page.pageSize, params).then(resp => {
        this.deviceCount = resp.data.result.total
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    alarmSearchHandle(page, params, done) {
      getDeviceAlarmRecord(page.pageIndex - 1, page.pageSize, { state: this.alarmSearchState }).then(resp => {
        this.alarmCount = resp.data.result.total
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    solve(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.alarmLogSolve()
      this.$refs.alarmTable.__searchHandle()
    },
    alarmLogSolve() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          alarmLogSolve(this.solveId, this.solveText).then(() => {
            this.$nextTick(() => {
              this.solveText = undefined
              this.$refs.solveModal.hide()
              this.$refs.alarmTable.__searchHandle()
            })
            this.solveText = undefined
          })
        }
      })
    },
    getVariant(index) {
      switch (index) {
        case 0:
          return 'light-danger'
        case 1:
          return 'light-success'
        case 2:
          return 'light-primary'
        default:
          return 'bg-transparent text-body'
      }
    },
    getLogVariant(log) {
      switch (log.action) {
        case '授权接口-登录':
          return 'danger'
        default:
          return 'success'
      }
    },
    toProduct(item) {
      if (!item.id) {
        return
      }
      this.$router.push({
        name: 'ec-product-view',
        params: { id: item.id },
      })
    },
    toDeviceList(item) {
      if (!item.id) {
        return
      }
      this.$router.push({
        name: 'device-center-device-manager-devices',
        params: { productId: item.id },
      })
    },
    rowDetail(data) {
      this.alarmDetail = data
      this.$refs.myModal.show()
    },
    rowSolve(data) {
      this.solveId = data.id
      this.$refs.solveModal.show()
    },
    authzQuickLinks(links) {
      return links.filter(item => this.$can(item.action, item.resource))
    },
    linkTo(link) {
      if (link.url) {
        window.open(link.url, '_blank')
      } else {
        this.$router.push({ name: link.route })
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@/@core/scss/base/bootstrap-extended/include';
  @import '@/@core/scss/base/components/include';
  .user-info {
    padding: 1rem;
    .u-avatar {
      width: 9rem;
      height: 9rem;
      border-radius: 9rem;
    }
  }
  .pd-card{
    height: 10rem;
    // cursor: pointer;
  }
  .pd-state-bg {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 3rem;
    top: .8rem;
    right: .8rem;
  }
  .pd-image {
    cursor: pointer;
  }
  .quick-to {
    border: 1px dashed $border-color;
    transition: all 0.25s ease-in-out;
    &:hover {
      border: 1px dashed $primary;
      transform: translateY(-5px);
      cursor: pointer;
      background-color: rgba($primary, .15);
    }
  }
  .dark-layout {
    .quick-to {
      border: 1px dashed $theme-dark-border-color;
    }
  }
</style>
